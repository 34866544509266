.header-styles {
    background-color: #bbdff9;
}
.MuiDataGrid-columnHeadersInner {
    font-weight: bolder;
}
.header-styles-detail {
    background-color: #E2E8F2;
}
.cell-styles { }

.incidence-row {
    background-color: #F48484;
}
.fixedIncidence-row {
    background-color: #82CA9D;
}
.warning-cell {
    background-color: #f6d331;
    font-weight:bolder;
}

.danger-cell {
    background-color: #F55050;
    color: white;
}

.MuiDataGrid-columnHeadersInner {
    background-color: #bbdff9;
}