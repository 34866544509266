.MuiTableCell-root,
.MuiInputBase-root {
	font-size: 1.5em !important;
}
.MuiButton-root {
	font-size: 1.1em !important;
}

.navigationButton {
	width: 220px;
}
