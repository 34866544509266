html {
  font-size: 14px;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.navbar-nav .nav-link.active {
    font-weight: bold;
}
.navbar-nav .active {
    color: #58bcf0 !important;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}